import React, { useState } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import intlFormat from 'date-fns/intlFormat';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { Divider } from 'components/divider/divider';
import { Grid } from 'components/grid/grid';
import { Popper } from 'components/popper/popper';
import { SectionTitle } from 'components/scenarios/components/section-title';
import { Text } from 'components/text/text';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { getApplicantsList } from 'store/applications';
import { selectedApplication } from 'store/applications';
import { getQualificationState } from 'store/qualification';
import { useEditingRights } from 'store/rights';
import { isEmpty } from 'utils';

import type { PullCreditModalProps } from 'components/scenarios/components/pull-credit-modal';
import type { Applicant } from 'types/applicant';

export type CreditScoreProps = {
    applicationId: number;
    lightQualification?: boolean;
};

const disableCreditPullStates = ['CLOSED', 'FUNDED', 'EXPIRED'];

export const CreditScore = ({
    applicationId,
    lightQualification = false,
}: CreditScoreProps) => {
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const applicants = useRecoilValue(getApplicantsList(applicationId));

    if (isEmpty(qualification)) {
        return null;
    }

    return (
        <Grid
            gridArea="creditScore"
            gridTemplateColumns="1fr"
            gridAutoFlow="column"
            gridAutoColumns="1fr"
            gridTemplateAreas={`
                "sectionTitle"
                "applicantsInfo"
                "applicantsInfoOld"
            `}
            css={css({
                height: 'fit-content',
                width: '100%',
            })}
        >
            <SectionTitle
                label="creditScoresLabel"
                gridArea="sectionTitle"
                gridTemplateColumns="1fr"
                gridTemplateAreas="title"
            />
            <Grid
                css={{
                    gridArea: 'applicantsInfo',
                    gridTemplateColumns: 'repeat(3, auto)',
                    gridAutoFlow: 'row',
                    marginTop: 10,
                    width: '100%',
                }}
            >
                {(applicants || []).map((applicant) => (
                    <CreditScoreRow
                        key={`creditRow-${applicant.applicantId}`}
                        applicationId={applicationId}
                        applicant={applicant}
                        lightQualification={lightQualification}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

export type CreditScoreRowProps = {
    applicationId: number;
    applicant: Applicant;
    dashboardView?: boolean;
    lightQualification?: boolean;
};

export const CreditScoreRow = ({
    applicationId,
    applicant,
    dashboardView = false,
    lightQualification = false,
}: CreditScoreRowProps) => {
    const {
        enableSoftPull: enableSoftPullLD,
        hardCreditPull,
        advisorTeamLead,
    } = useFlags();
    const showPullCreditCta =
        (enableSoftPullLD || hardCreditPull) && !dashboardView;

    const { i18n } = useI18n();
    const hasEditingRights = useEditingRights();
    const { applicationState } = useRecoilValue(selectedApplication);

    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    const { open: openPullCreditModal } =
        useModal<PullCreditModalProps>('pullCredit');

    const creditReportHit =
        applicant?.creditReport?.hit &&
        applicant?.creditReport?.pullType === 'HARD'; // we disable the pull credit only for HARD PULL
    const creditReportExpired = applicant?.creditReport?.expired;

    const creditReportIsHitAndExpired = !(
        creditReportHit && creditReportExpired
    );

    // if hit==false we have to show the button
    const isPullDisabled = creditReportHit
        ? creditReportIsHitAndExpired
        : false;

    const pullDisabled = advisorTeamLead
        ? false
        : applicant?.creditReport && isPullDisabled;

    return (
        <>
            <Grid data-testid="applicantName">
                <Text css={css({ fontSize: '12px' })}>
                    {`${applicant.firstName} ${applicant.lastName}`}
                </Text>
            </Grid>
            <Grid
                data-testid="creditScore"
                css={css({ textAlign: 'center', marginX: 10 })}
            >
                <Text
                    css={css({
                        fontWeight: 700,
                        fontSize: 14,
                        color: applicant?.creditReport?.hit
                            ? 'portGore'
                            : 'status-critical',
                    })}
                    tx={!applicant?.creditReport?.hit && 'noCredit'}
                >
                    {applicant?.creditReport?.hit && (
                        <Text>
                            {applicant?.creditReport?.score}{' '}
                            {`(${applicant?.creditReport?.pullType.toLowerCase()})`}
                        </Text>
                    )}
                </Text>
            </Grid>
            <Grid
                data-testid="pullCredit"
                css={{
                    minWidth: 110,
                    minHeight: 30,
                    justifyContent: 'end',
                }}
            >
                {showPullCreditCta && (
                    <>
                        {/* Wrap button with a Flex component because Browsers do not fire pointer events on disabled elements */}
                        {/* when disable point event need to be disable on the child to work */}
                        <Flex
                            ref={setReferenceElement}
                            onMouseOver={() => {
                                pullDisabled && setIsPopperOpen(true);
                            }}
                            onMouseLeave={() => {
                                pullDisabled && setIsPopperOpen(false);
                            }}
                        >
                            <Button
                                data-testid="buttonPullCredit"
                                variant="ghost"
                                css={{
                                    minWidth: 110,
                                    height: 30,
                                    pointerEvents: pullDisabled
                                        ? 'none'
                                        : undefined,
                                }}
                                onClick={() => {
                                    openPullCreditModal({
                                        applicationId,
                                        applicant,
                                        lightQualification,
                                    });
                                }}
                                disabled={
                                    pullDisabled ||
                                    !hasEditingRights ||
                                    disableCreditPullStates.includes(
                                        applicationState
                                    )
                                }
                            >
                                <Trans id="pullCredit" />
                            </Button>
                        </Flex>
                        <Popper
                            isOpen={isPopperOpen}
                            referenceElement={referenceElement}
                        >
                            <Trans
                                id="creditPulled"
                                values={
                                    applicant?.creditReport?.created && {
                                        date: intlFormat(
                                            new Date(
                                                applicant?.creditReport?.created
                                            ),
                                            {
                                                year: 'numeric',
                                                month: 'long',
                                                day: '2-digit',
                                            },
                                            { locale: i18n.locale }
                                        ),
                                    }
                                }
                            />
                        </Popper>
                    </>
                )}
            </Grid>
            <Grid data-testid="divider" css={{ gridColumnStart: 'span 3' }}>
                <Divider
                    css={css({
                        marginY: 10,
                        padding: 0,
                        height: 1,
                    })}
                />
            </Grid>
        </>
    );
};
