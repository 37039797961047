import { useTenantSetting } from '@nestoca/multi-tenant';
import { useRecoilValue } from 'recoil';

import {
    getIsHelocStandalone,
    getSelectedHelocProduct,
} from 'store/applications';
import { getQualificationState } from 'store/qualification';
import { MainApplicationType, MAIN_APPLICATION_TYPE } from 'types/application';
import { Qualification } from 'types/qualification';
import { round } from 'utils/math';

// if qualification.mortgageAmount isn't set we default to the calculatedMortgageAmount
export const getQualificationMortgageAmount = (qualification: Qualification) =>
    qualification?.mortgageAmount ??
    qualification?.ltvEx?.calculatedMortgageAmount;

export const getHasQualificationMortgageAmountDiscrepancy = (
    qualification: Qualification,
    mainType: MainApplicationType
) => {
    const {
        mortgageBalance = 0,
        additionalFundAmount = 0,
        helocAmount = 0,
        propertyValue = 0,
        downPaymentAmount = 0,
    } = qualification || {};

    const mortgageAmount = getQualificationMortgageAmount(qualification) || 0;

    switch (mainType) {
        case MAIN_APPLICATION_TYPE.NEW:
            const totalAmount = round(
                mortgageAmount + downPaymentAmount + helocAmount,
                2
            );

            return propertyValue !== totalAmount;

        case MAIN_APPLICATION_TYPE.REFINANCE:
            const fundsTotal = round(mortgageBalance + additionalFundAmount, 2);
            const lendingTotal = round(mortgageAmount + helocAmount, 2);

            return fundsTotal !== lendingTotal;

        default:
            return false;
    }
};

const ERROR_BORDER_COLOR = `var(--color-tomato-500)`;
const WARNING_BORDER_COLOR = `var(--color-status-warning-foreground-saturated)`;
const SUCCESS_BORDER_COLOR = 'var(--color-status-success-foreground-saturated)';

// These hooks are in place because with HELOC scenarios we don't have the extra warning color check
export const useGdsBorderColor = (applicationId: number) => {
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const { gds, helocAmount } = qualification || {};

    if (helocAmount) {
        if (gds > 35) {
            return ERROR_BORDER_COLOR;
        }

        return SUCCESS_BORDER_COLOR;
    }

    if (gds < 35) {
        return SUCCESS_BORDER_COLOR;
    }

    if (gds < 39) {
        return WARNING_BORDER_COLOR;
    }

    return ERROR_BORDER_COLOR;
};

export const useTdsBorderColor = (applicationId: number) => {
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const { tds, helocAmount } = qualification || {};

    if (helocAmount) {
        if (tds > 42) {
            return ERROR_BORDER_COLOR;
        }

        return SUCCESS_BORDER_COLOR;
    }

    if (tds < 42) {
        return SUCCESS_BORDER_COLOR;
    }

    if (tds < 44) {
        return WARNING_BORDER_COLOR;
    }

    return ERROR_BORDER_COLOR;
};

export const useLTVBorderColor = (applicationId: number) => {
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const { ltv, helocAmount } = qualification || {};

    const helocProduct = useRecoilValue(getSelectedHelocProduct(applicationId));
    const isStandAloneHeloc = useRecoilValue(
        getIsHelocStandalone(applicationId)
    );

    const {
        value: enableHeloc,
        settings: { ltvThresholdBasedOnHelocProduct },
    } = useTenantSetting('enableHeloc');

    // if the flag ltvThresholdBasedOnHelocProduct is enabled
    // use the heloc product's ltv to determine the border color only for standalone heloc
    const calculateLTVThresholdBasedOnHelocProduct =
        enableHeloc && ltvThresholdBasedOnHelocProduct && isStandAloneHeloc;

    if (helocAmount && calculateLTVThresholdBasedOnHelocProduct) {
        if (ltv > helocProduct?.cltv) {
            return ERROR_BORDER_COLOR;
        }

        return SUCCESS_BORDER_COLOR;
    }

    if (ltv > 80) {
        return ERROR_BORDER_COLOR;
    }

    return SUCCESS_BORDER_COLOR;
};

export const useHelocLTVBorderColor = (applicationId: number) => {
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const {
        settings: { helocLTVLimit },
    } = useTenantSetting('enableHeloc');

    const { ltvEx } = qualification || {};

    if (ltvEx?.heloclLtv > helocLTVLimit) {
        return ERROR_BORDER_COLOR;
    }

    return SUCCESS_BORDER_COLOR;
};
