import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import * as yup from 'yup';

import { Emoji } from 'components/emoji';
import { Grid } from 'components/grid/grid';
import { CreateNote } from 'components/notes-sidebar/create-note';
import { GeneralNotesList } from 'components/notes-sidebar/notes-list';
import { Text } from 'components/text/text';
import Tiptap from 'components/TipTap/Tiptap';
import { useToasts } from 'components/toast';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { notesState, useRefreshNotes } from 'store/notes';
import { isContextualSidebarOpenState } from 'store/ui';
import { useEditingContext } from 'utils/use-editing-context';
import { useIsTenant } from 'utils/useIsTenant';

import type { Note } from 'components/TipTap/types';

type Props = { applicationId: number };

const NotesContainer = styled(Flex)`
    margin-top: 30px;
    flex-direction: column;
    padding: 5px;
`;

const GeneralNotesView = ({ applicationId }: Props) => {
    const { clearEditingKey } = useEditingContext();
    const setNotesState = useSetRecoilState(notesState);
    const setIsContextualSidebarOpen = useSetRecoilState(
        isContextualSidebarOpenState
    );
    const { addToast } = useToasts();
    const [addNote, setAddNote] = useState<boolean>(false);
    const { isIGTenant } = useIsTenant();

    const noteState = useRecoilValue(notesState);
    const { i18n } = useI18n();
    const refreshNotes = useRefreshNotes(applicationId);
    const ref = useRef(null);

    useEffect(() => {
        setIsContextualSidebarOpen(true);
        setNotesState({
            applicantId: 0,
            section: 'information',
            noteType: 'internal',
            nodeType: 'general',
        });
        refreshNotes();
    }, []);

    const schema = yup.object().shape({
        text: yup.string().required(i18n._('error.fieldRequired')),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async ({ text }: Note) => {
        try {
            await apiClient.createApplicationNote(applicationId, 'general', {
                text,
            });

            clearEditingKey();
            methods.reset();
            refreshNotes();
            ref.current.clearContent();
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        }
    };

    const handleSubmit = async (note: Note) =>
        onSubmit && (await onSubmit(note));

    return (
        <NotesContainer mt={20} mb={30}>
            <Text
                css={css({ color: 'portGore', fontSize: 4, fontWeight: 700 })}
            >
                <Emoji
                    symbol="📝"
                    label="generalNotes"
                    css={css({ marginRight: 2 })}
                />
                <Trans id="generalNotes" />
            </Text>

            {!isIGTenant && (
                <CreateNote mb={3} onClick={() => setAddNote(!addNote)} />
            )}

            {addNote && (
                <FormProvider {...methods}>
                    <Grid
                        gridArea="addNote"
                        id="createNote"
                        as="form"
                        onSubmit={methods.handleSubmit(handleSubmit)}
                    >
                        <Tiptap
                            ref={ref}
                            applicationId={applicationId}
                            noteState={noteState}
                        />
                    </Grid>
                </FormProvider>
            )}

            <NotesContainer>
                <React.Suspense fallback={null}>
                    <GeneralNotesList applicationId={applicationId} />
                </React.Suspense>
            </NotesContainer>
        </NotesContainer>
    );
};

export const GeneralNotes = ({ applicationId }: { applicationId: number }) => {
    return (
        !!applicationId && (
            <React.Suspense fallback={null}>
                <GeneralNotesView applicationId={applicationId} />
            </React.Suspense>
        )
    );
};
